<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getStockCardReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <h2 class="font-weight-normal">
            {{ $t('management.stockMovement') }}
          </h2>
          <show-date></show-date>
          <search-pattern
            class="mb-4"
            :keyword="keyword"
            :searchBy="searchBy"
            v-on:getSort="setSearch"
          ></search-pattern>
          <CRow>
            <CCol sm="12" lg="12">
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              ></sortdata>
              <hr />
              <table
                id="stockCardTable"
                class="table table-bordered table-hover table-striped cursor"
              >
                <thead>
                  <tr>
                    <th class="text-center text-dark font-weight-normal">#</th>
                    <th></th>
                    <th class="text-dark font-weight-normal">{{ $t('productName') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('unit') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('category') }}</th>
                    <th class="text-right text-dark font-weight-normal">{{ $t('quantity') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in list" :key="index" @click="rowClicked(item)">
                    <td :id="'stockCardTableIndex' + index" style="width: 2%;" class="text-center text-dark font-weight-normal" :value="(item.orderIndex = index)">
                      <div style="margin-top: 6px">{{ item.index }}</div>
                    </td>
                    <td style="width: 5%;">
                      <div v-if="item.remoteImagePath"
                        :style="{
                          'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                          'background-size': 'cover',
                          'background-position': 'center center',
                          'width': 'auto',
                          'border-radius': '2px',
                          'aspect-ratio': '1.3',
                          'min-width': '50px',
                          'max-width': '50px',
                        }"
                      ></div>
                      <div
                        v-else
                        style="border-radius: 2px"
                        :style="{
                          'aspect-ratio': '4 / 3',
                          'min-width': '50px',
                          'max-width': '50px',
                          backgroundColor: item.indexColor,
                        }"
                      ></div>
                    </td>
                    <td style="width: 30%" class="text-dark font-weight-normal">
                        {{ item.name }}
                    </td>
                    <td style="width:10%" class="text-dark font-weight-normal">
                        {{ item.unit }}
                    </td>
                    <td style="width:20%" class="text-dark font-weight-normal">
                        {{ item.category }}
                    </td>
                    <td style="width:10%" class="text-right font-weight-normal">
                      <span
                        v-if="floatValue(item.onhandQty) < 0"
                        class="font-weight-normal text-danger"
                      >
                        {{ item.onhandQty }}
                      </span>
                      <span v-else class="font-weight-normal text-dark">
                        {{ item.onhandQty }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCol>
          </CRow>

          <pagination
            class="mt-4"
            :meta_data="meta_data"
            v-on:next="getStockCardReport"
          >
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import Sortdata from '@/containers/SortData'
import permis from '@/util/permission'
import SearchPattern from '@/containers/SearchPattern'

export default {
  components: {
    Pagination,
    Sortdata,
    SearchPattern,
  },
  data() {
    return {
      data: [],
      sortdata: this.$route.query.sort || 'SKUName',
      keyword: this.$route.query.keyword || '',
      searchBy: this.$route.query.searchBy || '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      searchLoadingButton: true,
      datasearch: [],
      categoryObjectId: '',
      countCategory: '',
      sortOrder: 'asc',
      sort_value: {
        name: 'SKUName',
        category: 'category.name',
        updated_at: 'updated_at',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
      
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata === 'sortByNumMin' || sortdata === 'sortByNumMax') {
          sortdata = 'SKUPrice'
        }

        if (sortdata === 'onhandQtyMin' || sortdata === 'onhandQtyMax') {
          sortdata = 'ProductSKUStock.onhandQty'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    list() {
      const data = this.data
      let detail = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 50
      
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const index = (currentPage * limit) + (i + 1)

        let onhandQty = ''
        if (item.skuType === 'SV' || item.skuType === 'BOM') {
          onhandQty = this.$t('nonStock')
        } else {
          const productSKUStock = item.ProductSKUStock || {}
          onhandQty = util.convertOnhand(productSKUStock.onhandQty || 0)
        }

        let price = ''
        if (item.SKUPrice == undefined) {
          price = '-'
        } else {
          price = this.convertCurrency(item.SKUPrice)
        }

        let remoteImagePath = ''
        let backgroundColor = ''
        if (item.remoteImagePath != undefined) {
          remoteImagePath = item.remoteImagePath
          backgroundColor = ''
        } else {
          remoteImagePath = ''
          backgroundColor = util.generateColor(item.indexColor)
        }

        // Parse PLU data
        const SKUName = item.SKUName || ''
        const productPLU = item.productPLU || {}
        const PLUCode = productPLU.PLUCode || 'N/A'

        // Parse Category Name
        const category = item.category || {}
        const categoryName = category.name || 'N/A'
        
        // Parse Unit name
        const unit = item.unit || {}
        const unitName = unit.name || 'N/A'
        
        detail.push({
          index: index,
          remoteImagePath: remoteImagePath,
          backgroundColor: backgroundColor,
          name: SKUName,
          unit: unitName,
          onhandQty: onhandQty,
          price: price,
          productSKUObjectId: data[i].objectId,
          PLUCode: PLUCode,
          category: categoryName,
          indexColor: util.generateColor(item.indexColor),
          sortPrice: item.SKUPrice,
          created_at: item.created_at,
        })
      }
      return detail
    },
    queryparams() {
      return (
        'sort=' +
        this.sortdata +
        '&page=' +
        this.meta_data.current_page +
        '&searchBy=' +
        this.searchBy +
        '&keyword=' +
        this.keyword
      )
    },
  },
  created() {
    this.getStockCardReport()
  },
  methods: {
    ...util,
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    rowClicked(item) {
      this.$router.push(
        `/inventory/stockcard/${this.shopObjectId}/${item.productSKUObjectId}?${this.queryparams}`
      )
    },
    setSort(value) {
      this.sortdata = value
      this.getStockCardReport()
    },
    setSearch(searchBy, keyword) {
      this.keyword = keyword
      this.searchBy = searchBy
      this.getStockCardReport()
    },
    getStockCardReport(page = this.$route.query.page || 1) {
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      let sortOrder = ''
      let params = {}

      if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'onhandQtyMin'
      ) {
        sortOrder = 'asc'
      } else if (
        this.sortdata === 'sortByNumMax' ||
        this.sortdata === 'onhandQtyMax' ||
        this.sortdata === 'updated_at'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }

      if (this.searchBy === '') {
        params = {
          shopObjectId: shopObjectId,
          page: page,
          limit: 50,
          sortattr: this.sortAttr,
          sortorder: sortOrder,
        }
      } else {
        params = {
          shopObjectId: shopObjectId,
          searchVal: this.keyword,
          searchAttr: this.searchBy,
          page: page,
          limit: 50,
          sortattr: this.sortAttr,
          sortorder: sortOrder,
        }
      }

      axios({
        url: '/inventory/v2.0/get/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data
        if (res.data.paginate.items == undefined) {
          this.meta_data.items = 0
        } else {
          this.meta_data.items = res.data.paginate.items
        }
        this.meta_data.last_page = res.data.paginate.pageCount
        this.meta_data.current_page = res.data.paginate.currentPage
        this.meta_data.itemCount = res.data.paginate.itemCount
        this.meta_data.limit = res.data.paginate.perPage
        this.searchLoadingButton = true
        this.loadingButton = true
      })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>


<style>
.square-box {
  width: 20%;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  margin-left: 6px;
}
.square-box:before {
  content: '';
  display: block;
  padding-top: 100%;
}
</style>