var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('select-shop',{attrs:{"loadingButton":_vm.loadingButton},on:{"getData":_vm.getStockCardReport}}),(_vm.isPermission)?_c('div',[_c('CCard',{staticClass:"shadow-sm"},[_c('CCardBody',[_c('h2',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t('management.stockMovement'))+" ")]),_c('show-date'),_c('search-pattern',{staticClass:"mb-4",attrs:{"keyword":_vm.keyword,"searchBy":_vm.searchBy},on:{"getSort":_vm.setSearch}}),_c('CRow',[_c('CCol',{attrs:{"sm":"12","lg":"12"}},[_c('sortdata',{attrs:{"sort_value":_vm.sort_value,"sortdata":_vm.sortdata},on:{"getSort":_vm.setSort}}),_c('hr'),_c('table',{staticClass:"table table-bordered table-hover table-striped cursor",attrs:{"id":"stockCardTable"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center text-dark font-weight-normal"},[_vm._v("#")]),_c('th'),_c('th',{staticClass:"text-dark font-weight-normal"},[_vm._v(_vm._s(_vm.$t('productName')))]),_c('th',{staticClass:"text-dark font-weight-normal"},[_vm._v(_vm._s(_vm.$t('unit')))]),_c('th',{staticClass:"text-dark font-weight-normal"},[_vm._v(_vm._s(_vm.$t('category')))]),_c('th',{staticClass:"text-right text-dark font-weight-normal"},[_vm._v(_vm._s(_vm.$t('quantity')))])])]),_c('tbody',_vm._l((_vm.list),function(item,index){return _c('tr',{key:index,on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',{staticClass:"text-center text-dark font-weight-normal",staticStyle:{"width":"2%"},attrs:{"id":'stockCardTableIndex' + index,"value":(item.orderIndex = index)}},[_c('div',{staticStyle:{"margin-top":"6px"}},[_vm._v(_vm._s(item.index))])]),_c('td',{staticStyle:{"width":"5%"}},[(item.remoteImagePath)?_c('div',{style:({
                        'background-image': `url('${item.remoteImagePath}'), url('${_vm.noImg}')`,
                        'background-size': 'cover',
                        'background-position': 'center center',
                        'width': 'auto',
                        'border-radius': '2px',
                        'aspect-ratio': '1.3',
                        'min-width': '50px',
                        'max-width': '50px',
                      })}):_c('div',{staticStyle:{"border-radius":"2px"},style:({
                        'aspect-ratio': '4 / 3',
                        'min-width': '50px',
                        'max-width': '50px',
                        backgroundColor: item.indexColor,
                      })})]),_c('td',{staticClass:"text-dark font-weight-normal",staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-dark font-weight-normal",staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(item.unit)+" ")]),_c('td',{staticClass:"text-dark font-weight-normal",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(item.category)+" ")]),_c('td',{staticClass:"text-right font-weight-normal",staticStyle:{"width":"10%"}},[(_vm.floatValue(item.onhandQty) < 0)?_c('span',{staticClass:"font-weight-normal text-danger"},[_vm._v(" "+_vm._s(item.onhandQty)+" ")]):_c('span',{staticClass:"font-weight-normal text-dark"},[_vm._v(" "+_vm._s(item.onhandQty)+" ")])])])}),0)])],1)],1),_c('pagination',{staticClass:"mt-4",attrs:{"meta_data":_vm.meta_data},on:{"next":_vm.getStockCardReport}})],1)],1)],1):_c('div',[_c('access-data')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }